import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-main-site-container',
  templateUrl: './main-site-container.component.html',
  styleUrls: ['./main-site-container.component.css']
})
export class MainSiteContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }




}
